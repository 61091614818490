import { isTableNumber } from './is-table-number';
import { OrderList } from '../types/order';
import { FulfillmentMethods } from '../constants';

export const printFulfilmentMethod = ({
  fulfilmentMethod,
  servingNumber,
  tableNumber,
  specialFulfillmentMethod,
}: OrderList) => {
  if (specialFulfillmentMethod) {
    return specialFulfillmentMethod;
  }

  if (tableNumber && !fulfilmentMethod) {
    return isTableNumber(tableNumber) ? `Table ${tableNumber}` : tableNumber;
  }

  if (fulfilmentMethod === FulfillmentMethods.TABLE_SIDE)
    return `Table ${servingNumber}`;
  if (fulfilmentMethod === FulfillmentMethods.ROOM_SERVICE)
    return `Room ${servingNumber}`;
  if (fulfilmentMethod === FulfillmentMethods.DELIVERY) return 'Delivery';
  if (fulfilmentMethod === FulfillmentMethods.TAKE_OUT) return 'Takeout';
  return fulfilmentMethod;
};
