import { createSlice } from '@reduxjs/toolkit';
import { dispatch, getState } from '../store';
import crave from '../../apis/crave';
import axios from 'axios';
import sortBySortOrder from '../../utils/sortBySortOrder';
import formatApiError from '../../utils/formatApiError';
import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import reorderListItems from '../../utils/reorderListItems';
import { itemAvailability } from '../../constants/constants';
import { openToast } from './snackbar';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  error: null,
  items: [],
  formattedItems: {
    tableHeadings: ['NAME', '', 'PRICE', 'AVAILABILITY', ''],
    tableRows: [],
  },
  categories: [],
  formattedCategories: {
    tableHeadings: ['NAME', '', 'ITEMS', 'STATUS', ''],
    tableRows: [],
  },
  categoriesAndItems: [],
  categoryDetails: {
    id: '',
    label: '',
    deleted: false,
  },
  isCreateCategoryModalOpen: false,
  isDeleteCategoryModalOpen: false,
  isEditCategoryModalOpen: false,
  isCreateItemModalOpen: false,
  isEditItemModalOpen: false,
  isEditItemOpen: false,
  isDeleteItemModalOpen: false,
  isMoveItemModalOpen: false,
  itemDetailsForMoveItem: {
    id: '',
    from: '',
  },
  itemDetailsForDeleteItem: {
    id: '',
    category: '',
  },
  itemDetail: {
    name: '',
    description: '',
    price: '',
    images: [],
    newImage: null,
    category: '',
    modifierGroups: [],
    sortOrder: 0,
    availability: itemAvailability.AVAILABLE,
  },
};

const slice = createSlice({
  name: 'categoriesAndItems',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },

    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // ----------------------------------------------------------------------

    // OPEN CATEGORY MODAL
    openCategoryModal(state, action) {
      state[action.payload] = true;
    },

    // CLOSE CATEGORY MODAL
    closeCategoryModal(state, action) {
      state[action.payload] = false;
    },

    //SET DELETE CATEGORY DATA
    setDeleteCategoryModalData(state, action) {
      state.isDeleteCategoryModalOpen =
        action.payload.isDeleteCategoryModalOpen;
      state.categoryDetails = {
        id: action.payload.id || '',
      };
    },

    //SET CREATE CATEGORY DATA
    setEditCategoryModalData(state, action) {
      state.isEditCategoryModalOpen = action.payload.isEditCategoryModalOpen;
      state.categoryDetails = {
        id: action.payload.id || '',
        label: action.payload.label || '',
        deleted: action.payload.deleted || false,
      };
    },

    getCategoriesAndItemsSuccess(state, action) {
      state.loading = false;
      state.categoriesAndItems = action.payload.categoriesAndItems;
      state.formattedCategories.tableRows = action.payload.formattedCategories;
      state.formattedItems.tableRows = action.payload.formattedItems;
    },

    createCategorySuccess(state, action) {
      state.loading = false;
      state.categoriesAndItems = action.payload.categoriesAndItems;
      state.formattedCategories.tableRows = action.payload.formattedCategories;
      state.isCreateCategoryModalOpen = false;
    },

    deleteCategorySuccess(state, action) {
      state.loading = false;
      state.categoriesAndItems = action.payload.categoriesAndItems;
      state.formattedCategories.tableRows = action.payload.formattedCategories;
      state.categoryDetails = initialState.categoryDetails;
      state.isDeleteCategoryModalOpen = false;
    },

    editCategorySuccess(state, action) {
      state.loading = false;
      state.categoriesAndItems = action.payload.categoriesAndItems;
      state.formattedCategories.tableRows = action.payload.formattedCategories;
      state.categoryDetails = initialState.categoryDetails;
      state.isEditCategoryModalOpen = false;
    },

    // ----------------------------------------------------------------------

    setItemData(state, action) {
      state[action.payload.key] = action.payload.value;
      state.itemDetail = {
        id: action.payload.id || '',
        name: action.payload.name || '',
        description: action.payload.description || '',
        price: action.payload.price || '',
        images: action.payload.images || [],
        newImage: action.payload.newImage || null,
        category: action.payload.category || '',
        modifierGroups: action.payload.modifierGroups || [],
        sortOrder: action.payload.sortOrder || 0,
        availability: action.payload.availability || itemAvailability.AVAILABLE,
      };
    },

    closeItemModals(state) {
      state.isCreateItemModalOpen = false;
      state.isEditItemModalOpen = false;
      state.isEditItemOpen = false;
    },

    setDeleteItemModalData(state, action) {
      state.isDeleteItemModalOpen = action.payload.isDeleteItemModalOpen;
      state.itemDetailsForDeleteItem = {
        id: action.payload.id || '',
        category: action.payload.category || '',
      };
    },

    createItemSuccess(state, action) {
      state.loading = false;
      state.categoriesAndItems = action.payload.categoriesAndItems;
      state.formattedItems.tableRows = action.payload.formattedItems;
      state.itemDetail = initialState.itemDetail;
      state.isCreateItemModalOpen = false;
      state.isEditItemOpen = false;
    },

    editItemSuccess(state, action) {
      state.loading = false;
      state.categoriesAndItems = action.payload.categoriesAndItems;
      state.formattedItems.tableRows = action.payload.formattedItems;
      state.itemDetail = initialState.itemDetail;
      state.isEditItemModalOpen = false;
      state.isEditItemOpen = false;
    },

    availabilityUpdateSuccess(state, action) {
      state.loading = false;
      state.categoriesAndItems = action.payload.categoriesAndItems;
      state.formattedItems.tableRows = action.payload.formattedItems;
    },

    deleteItemSuccess(state, action) {
      state.loading = false;
      state.categoriesAndItems = action.payload.categoriesAndItems;
      state.formattedItems.tableRows = action.payload.formattedItems;
      state.itemDetailsForDeleteItem = initialState.itemDetailsForDeleteItem;
      state.isDeleteItemModalOpen = false;
    },

    cloneItemSuccess(state, action) {
      state.loading = false;
      state.categoriesAndItems = action.payload.categoriesAndItems;
      state.formattedItems.tableRows = action.payload.formattedItems;
    },

    setMoveItemModalData(state, action) {
      state.isMoveItemModalOpen = action.payload.isMoveItemModalOpen;
      state.itemDetailsForMoveItem = {
        id: action.payload.id || '',
        from: action.payload.from || '',
      };
    },

    moveItemSuccess(state, action) {
      state.loading = false;
      state.categoriesAndItems = action.payload.categoriesAndItems;
      state.formattedItems.tableRows = action.payload.formattedItems;
      state.isMoveItemModalOpen = false;
      state.itemDetailsForMoveItem = initialState.itemDetailsForMoveItem;
    },

    reOrderCategoriesSuccess(state, action) {
      state.loading = false;
      state.categoriesAndItems = action.payload.categoriesAndItems;
      state.formattedCategories.tableRows = action.payload.formattedCategories;
    },

    reOrderItemsSuccess(state, action) {
      state.loading = false;
      state.categoriesAndItems = action.payload.categoriesAndItems;
      state.formattedItems.tableRows = action.payload.formattedItems;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  openCategoryModal,
  closeCategoryModal,
  setDeleteCategoryModalData,
  setEditCategoryModalData,
  setItemData,
  closeItemModals,
  setDeleteItemModalData,
  setMoveItemModalData,
} = slice.actions;

// ----------------------------------------------------------------------

const handleFormatCategoriesAndItemsData = (categoriesAndItems) => {
  const items = [];
  const categories = categoriesAndItems.map((categoryAndItem) => {
    items.push(...categoryAndItem.items);
    return {
      ...categoryAndItem.category,
      numOfItems: categoryAndItem.items.length,
    };
  });

  const formattedCategories = categories.map((category) => {
    return {
      item: category,
      tableRow: [
        { text: category.label },
        { text: '' },
        {
          text: `${category.numOfItems} ${
            category.numOfItems > 1 ? 'items' : 'item'
          }`,
        },
        { text: category.deleted ? 'Inactive' : 'Active' },
        { text: '' },
      ],
    };
  });

  const getItemAvailability = (availability) => {
    const { AVAILABLE, SOLD_OUT_TODAY } = itemAvailability;

    switch (availability) {
      case AVAILABLE:
        return 'Available';
      case SOLD_OUT_TODAY:
        return 'Sold Out Today';
      default:
        return 'Sold Out Indefinitely';
    }
  };

  const formattedItems = items.map((item) => {
    return {
      item,
      tableRow: [
        { text: item.name },
        { text: '' },
        { text: `$${item.price}` },
        { text: getItemAvailability(item.availability) },
        { text: '' },
      ],
    };
  });

  return {
    categoriesAndItems,
    items,
    categories,
    formattedCategories,
    formattedItems,
  };
};

// ----------------------------------------------------------------------

export function getCategoriesAndItems() {
  return async () => {
    try {
      const { auth } = getState();
      const { merchantId, locationId, token } = auth.user;
      dispatch(slice.actions.startLoading());

      const params = { headers: { Authorization: `Bearer ${token}` } };
      const fetchCategories = crave.get(
        `merchants/${merchantId}/locations/${locationId}/categories`,
        params
      );
      const fetchItems = crave.get(
        `merchants/${merchantId}/locations/${locationId}/products`,
        params
      );

      const [categoriesResp, productResp] = await axios.all([
        fetchCategories,
        fetchItems,
      ]);

      const categories = sortBySortOrder(categoriesResp.data.categories);
      const items = productResp.data.products;

      const categoriesAndItems = categories.map((category) => {
        return {
          category,
          items: sortBySortOrder(
            items.filter((product) => product.category === category.id)
          ),
        };
      });

      const { formattedCategories, formattedItems } =
        handleFormatCategoriesAndItemsData(categoriesAndItems);

      dispatch(
        slice.actions.getCategoriesAndItemsSuccess({
          categoriesAndItems,
          formattedCategories,
          formattedItems,
        })
      );
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
    }
  };
}

// ------------------------------CATEGORIES----------------------------------------

export function createCategory(newCategory) {
  return async () => {
    const { auth, categoriesAndItems: categoriesAndItemsState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { categoriesAndItems } = categoriesAndItemsState;
    dispatch(slice.actions.startLoading());

    try {
      const params = { headers: { Authorization: `Bearer ${token}` } };
      const { data } = await crave.post(
        `merchants/${merchantId}/locations/${locationId}/categories`,
        newCategory,
        params
      );
      const newData = {
        category: data.data,
        items: [],
      };

      const {
        formattedCategories,
        formattedItems,
        categoriesAndItems: categoriesAndItemsNew,
      } = handleFormatCategoriesAndItemsData([...categoriesAndItems, newData]);

      dispatch(
        slice.actions.createCategorySuccess({
          categoriesAndItems: categoriesAndItemsNew,
          formattedCategories,
          formattedItems,
        })
      );

      dispatch(openToast({ message: 'New category added', type: 'success' }));
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCategory() {
  return async () => {
    const { auth, categoriesAndItems: categoriesAndItemsState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { categoryDetails, categoriesAndItems } = categoriesAndItemsState;

    dispatch(slice.actions.startLoading());

    try {
      const params = { headers: { Authorization: `Bearer ${token}` } };
      await crave.delete(
        `merchants/${merchantId}/locations/${locationId}/categories/${categoryDetails.id}`,
        params
      );

      const {
        formattedCategories,
        formattedItems,
        categoriesAndItems: categoriesAndItemsNew,
      } = handleFormatCategoriesAndItemsData(
        categoriesAndItems.filter(
          (categoryAndItems) =>
            categoryAndItems.category.id !== categoryDetails.id
        )
      );

      dispatch(
        slice.actions.deleteCategorySuccess({
          categoriesAndItems: categoriesAndItemsNew,
          formattedCategories,
          formattedItems,
        })
      );

      dispatch(openToast({ message: 'Category deleted', type: 'info' }));
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
    }
  };
}

// ----------------------------------------------------------------------

export function editCategory(updateCategory) {
  return async () => {
    const { auth, categoriesAndItems: categoriesAndItemsState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { categoryDetails, categoriesAndItems } = categoriesAndItemsState;

    dispatch(slice.actions.startLoading());

    try {
      const params = { headers: { Authorization: `Bearer ${token}` } };
      const { data } = await crave.put(
        `merchants/${merchantId}/locations/${locationId}/categories/${categoryDetails.id}`,
        updateCategory,
        params
      );

      const updatedCategoriesAndItems = categoriesAndItems.map(
        (categoryAndItems) => {
          if (categoryAndItems.category.id === categoryDetails.id) {
            return {
              ...categoryAndItems,
              category: { ...categoryAndItems.category, ...data.data },
            };
          }

          return categoryAndItems;
        }
      );

      const { formattedCategories, categoriesAndItems: categoriesAndItemsNew } =
        handleFormatCategoriesAndItemsData(updatedCategoriesAndItems);

      dispatch(
        slice.actions.editCategorySuccess({
          categoriesAndItems: categoriesAndItemsNew,
          formattedCategories,
        })
      );
      dispatch(
        openToast({ message: 'Category updated successfully', type: 'success' })
      );
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
    }
  };
}

// -------------------------------ITEMS---------------------------------------

const getFormDataForCreateItem = (newItem) => {
  const {
    name,
    price,
    description,
    newImage,
    category,
    modifierGroups,
    sortOrder = 100,
    availability,
  } = newItem;
  const newItemFormData = new FormData();

  newItemFormData.append('name', name);
  newItemFormData.append('price', price);
  newItemFormData.append('category', category);
  newItemFormData.append('sortOrder', sortOrder);
  newItemFormData.append('availability', availability);

  if (description) {
    newItemFormData.append('description', description);
  }

  if (newImage) {
    newItemFormData.append('image', newImage);
  }

  if (modifierGroups.length > 0) {
    newItemFormData.append(
      'modifierGroups',
      JSON.stringify(modifierGroups.map((modifierGroup) => modifierGroup.id))
    );
  }

  return newItemFormData;
};

export function createItem(newItem) {
  return async () => {
    const { auth, categoriesAndItems: categoriesAndItemsState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { categoriesAndItems } = categoriesAndItemsState;

    dispatch(slice.actions.startLoading());

    try {
      const params = { headers: { Authorization: `Bearer ${token}` } };
      const { data } = await crave.post(
        `merchants/${merchantId}/locations/${locationId}/products`,
        getFormDataForCreateItem(newItem),
        params
      );

      const updatedCategoriesAndItems = categoriesAndItems.map(
        (categoryAndItems) => {
          if (categoryAndItems.category.id === newItem.category) {
            return {
              ...categoryAndItems,
              items: [...categoryAndItems.items, data.data],
            };
          }
          return categoryAndItems;
        }
      );

      const { formattedItems, categoriesAndItems: categoriesAndItemsNew } =
        handleFormatCategoriesAndItemsData(updatedCategoriesAndItems);

      dispatch(
        slice.actions.createItemSuccess({
          categoriesAndItems: categoriesAndItemsNew,
          formattedItems,
        })
      );

      dispatch(
        openToast({ message: 'Item added into the menu', type: 'success' })
      );
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
    }
  };
}

// ----------------------------------------------------------------------

const getFormDataForEditItem = (updateItem, oldItem) => {
  const { name, price, description, newImage, modifierGroups } = updateItem;
  const newItemFormData = new FormData();

  if (name !== oldItem.name) {
    newItemFormData.append('name', name);
  }
  if (price !== oldItem.price) {
    newItemFormData.append('price', price);
  }

  if (description !== oldItem.description) {
    newItemFormData.append('description', description);
  }

  if (!_isEqual(modifierGroups, oldItem.modifierGroups)) {
    newItemFormData.append(
      'modifierGroups',
      JSON.stringify(modifierGroups.map((modifierGroup) => modifierGroup.id))
    );
  }

  if (newImage) {
    newItemFormData.append('image', newImage);
  }

  return newItemFormData;
};

export function editItem(updateItem, oldItem) {
  return async () => {
    const { auth, categoriesAndItems: categoriesAndItemsState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { categoriesAndItems } = categoriesAndItemsState;
    dispatch(slice.actions.startLoading());

    try {
      const params = { headers: { Authorization: `Bearer ${token}` } };
      const { data } = await crave.put(
        `merchants/${merchantId}/locations/${locationId}/products/${updateItem.id}`,
        getFormDataForEditItem(updateItem, oldItem),
        params
      );
      const updatedCategoriesAndItems = categoriesAndItems.map(
        (categoryAndItems) => {
          if (categoryAndItems.category.id === updateItem.category) {
            return {
              ...categoryAndItems,
              items: categoryAndItems.items.map((item) => {
                if (item.id === updateItem.id) {
                  return data.data;
                }
                return item;
              }),
            };
          }

          return categoryAndItems;
        }
      );

      const { formattedItems, categoriesAndItems: categoriesAndItemsNew } =
        handleFormatCategoriesAndItemsData(updatedCategoriesAndItems);

      dispatch(
        slice.actions.editItemSuccess({
          categoriesAndItems: categoriesAndItemsNew,
          formattedItems,
        })
      );
      dispatch(
        openToast({ message: 'Product updated in the menu', type: 'success' })
      );
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteItem() {
  return async () => {
    const { auth, categoriesAndItems: categoriesAndItemsState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { itemDetailsForDeleteItem, categoriesAndItems } =
      categoriesAndItemsState;

    dispatch(slice.actions.startLoading());
    try {
      const params = { headers: { Authorization: `Bearer ${token}` } };
      await crave.delete(
        `merchants/${merchantId}/locations/${locationId}/products/${itemDetailsForDeleteItem.id}`,
        params
      );

      const updatedCategoriesAndItems = categoriesAndItems.map(
        (categoryAndItems) => {
          if (
            categoryAndItems.category.id === itemDetailsForDeleteItem.category
          ) {
            return {
              ...categoryAndItems,
              items: categoryAndItems.items.filter(
                (item) => item.id !== itemDetailsForDeleteItem.id
              ),
            };
          }

          return categoryAndItems;
        }
      );

      const { formattedItems, categoriesAndItems: categoriesAndItemsNew } =
        handleFormatCategoriesAndItemsData(updatedCategoriesAndItems);

      dispatch(
        slice.actions.deleteItemSuccess({
          categoriesAndItems: categoriesAndItemsNew,
          formattedItems,
        })
      );
      dispatch(
        openToast({ message: 'Product deleted successfully', type: 'info' })
      );
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
    }
  };
}

// ----------------------------------------------------------------------

export function cloneItem(item) {
  return async () => {
    const { auth, categoriesAndItems: categoriesAndItemsState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { categoriesAndItems } = categoriesAndItemsState;
    dispatch(slice.actions.startLoading());

    try {
      const params = { headers: { Authorization: `Bearer ${token}` } };
      const { data } = await crave.post(
        `merchants/${merchantId}/locations/${locationId}/products/${item.id}/clone`,
        null,
        params
      );

      const updatedCategoriesAndItems = categoriesAndItems.map(
        (categoryAndItems) => {
          if (categoryAndItems.category.id === item.category) {
            return {
              ...categoryAndItems,
              items: [...categoryAndItems.items, data.data],
            };
          }

          return categoryAndItems;
        }
      );

      const { formattedItems, categoriesAndItems: categoriesAndItemsNew } =
        handleFormatCategoriesAndItemsData(updatedCategoriesAndItems);

      dispatch(
        slice.actions.cloneItemSuccess({
          categoriesAndItems: categoriesAndItemsNew,
          formattedItems,
        })
      );
      dispatch(
        openToast({ message: 'Product duplicated successfully', type: 'info' })
      );
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
    }
  };
}

// ----------------------------------------------------------------------

export function moveItemToCategory(to) {
  return async () => {
    const { auth, categoriesAndItems: categoriesAndItemsState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { itemDetailsForMoveItem, categoriesAndItems } =
      categoriesAndItemsState;
    dispatch(slice.actions.startLoading());

    try {
      const params = { headers: { Authorization: `Bearer ${token}` } };
      const { data } = await crave.post(
        `merchants/${merchantId}/locations/${locationId}/products/${itemDetailsForMoveItem.id}/move`,
        {
          categories: {
            from: itemDetailsForMoveItem.from,
            to,
          },
        },
        params
      );

      const updatedCategoriesAndItems = categoriesAndItems.map(
        (categoryAndItems) => {
          if (categoryAndItems.category.id === itemDetailsForMoveItem.from) {
            return {
              ...categoryAndItems,
              items: categoryAndItems.items.filter(
                (item) => item.id !== data.data.id
              ),
            };
          }
          if (categoryAndItems.category.id === to) {
            return {
              ...categoryAndItems,
              items: [
                ...categoryAndItems.items,
                { ...data.data, category: to },
              ],
            };
          }
          return categoryAndItems;
        }
      );

      const { formattedItems, categoriesAndItems: categoriesAndItemsNew } =
        handleFormatCategoriesAndItemsData(updatedCategoriesAndItems);

      dispatch(
        slice.actions.moveItemSuccess({
          categoriesAndItems: categoriesAndItemsNew,
          formattedItems,
        })
      );

      dispatch(
        openToast({
          message: 'Product moved to category successfully',
          type: 'info',
        })
      );
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
    }
  };
}

// ----------------------------------------------------------------------
const sendReOrderedCategoriesToServer = _debounce(
  async (url, categoriesAndItemsNew, token) => {
    const data = categoriesAndItemsNew.map(
      (categoryAndItems) => categoryAndItems.category.id
    );
    const params = { headers: { Authorization: `Bearer ${token}` } };
    await crave.post(url, data, params);
    dispatch(
      openToast({ message: 'Categories reordered successfully', type: 'info' })
    );
  },
  2000
);

export function reOrderCategories(result) {
  return async () => {
    const { auth, categoriesAndItems: categoriesAndItemsState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { categoriesAndItems } = categoriesAndItemsState;

    dispatch(slice.actions.startLoading());

    try {
      const updatedCategories = reorderListItems(
        categoriesAndItems,
        result.source.index,
        result.destination.index
      );

      const { formattedCategories, categoriesAndItems: categoriesAndItemsNew } =
        handleFormatCategoriesAndItemsData(updatedCategories);

      dispatch(
        slice.actions.reOrderCategoriesSuccess({
          categoriesAndItems: categoriesAndItemsNew,
          formattedCategories,
        })
      );

      sendReOrderedCategoriesToServer(
        `merchants/${merchantId}/locations/${locationId}/categories/reorder`,
        categoriesAndItemsNew,
        token
      );
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
    }
  };
}

// ----------------------------------------------------------------------
const sendReOrderedItemsToServer = _debounce(
  async (url, items, categoryId, token) => {
    const data = {
      category: categoryId,
      products: items.map((item) => item.id),
    };
    const params = { headers: { Authorization: `Bearer ${token}` } };
    await crave.post(url, data, params);
    dispatch(
      openToast({ message: 'Products reordered successfully', type: 'info' })
    );
  },
  2000
);

export function reOrderItems(result) {
  return async () => {
    const { auth, categoriesAndItems: categoriesAndItemsState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { categoriesAndItems } = categoriesAndItemsState;

    dispatch(slice.actions.startLoading());

    try {
      const categoryId = result.source.droppableId;
      let items = [];
      const updatedCategoriesAndItems = categoriesAndItems.map(
        (categoryAndItems) => {
          if (categoryAndItems.category.id === categoryId) {
            const updatedItems = reorderListItems(
              categoryAndItems.items,
              result.source.index,
              result.destination.index
            );
            items = updatedItems;
            return { ...categoryAndItems, items: updatedItems };
          }

          return categoryAndItems;
        }
      );

      const { formattedItems, categoriesAndItems: categoriesAndItemsNew } =
        handleFormatCategoriesAndItemsData(updatedCategoriesAndItems);

      dispatch(
        slice.actions.reOrderItemsSuccess({
          categoriesAndItems: categoriesAndItemsNew,
          formattedItems,
        })
      );

      sendReOrderedItemsToServer(
        `merchants/${merchantId}/locations/${locationId}/products/reorder`,
        items,
        categoryId,
        token
      );
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
    }
  };
}

// ----------------------------------------------------------------------

export function updateItemAvailability(itemData) {
  return async () => {
    const { categoriesAndItems: categoriesAndItemsState } = getState();
    const { categoriesAndItems } = categoriesAndItemsState;

    dispatch(slice.actions.startLoading());

    try {
      const updatedCategoriesAndItems = categoriesAndItems.map(
        (categoryAndItems) => {
          if (categoryAndItems.category.id === itemData.category) {
            return {
              ...categoryAndItems,
              items: categoryAndItems.items.map((item) => {
                if (item.id === itemData.id) {
                  return { ...item, availability: itemData.newAvailability };
                }
                return item;
              }),
            };
          }

          return categoryAndItems;
        }
      );

      const { formattedItems, categoriesAndItems: categoriesAndItemsNew } =
        handleFormatCategoriesAndItemsData(updatedCategoriesAndItems);

      dispatch(
        slice.actions.availabilityUpdateSuccess({
          categoriesAndItems: categoriesAndItemsNew,
          formattedItems,
        })
      );
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
    }
  };
}
